import { TextField, Grid } from '@material-ui/core';

const Field = (props) => {
  return (
    <Grid item xs={12}>
      <TextField name={props.name} label={props.label||props.name} value={props.value} onChange={props.onChange} key={props.index} variant="filled" fullWidth InputLabelProps={{shrink: true}} />
    </Grid>
  )
}

export default Field;