import { ENDPOINT, SEND_ENDPOINT, GET_CONFIG, POST_CONFIG, handleErrors } from './config';
import { acuityVals, severityVals } from './constants';
import mockReports from './mock-reports';
import analysis from './analysis';
import Faker from 'faker';

const endpoint = new URL(ENDPOINT);

export const getAnalysis = async () => {
  const config = { ...GET_CONFIG };
  const analysisResp = await fetch(`${ENDPOINT}javascript/analysis.json`, config).then(handleErrors);
  const resp =  await analysisResp.json();
  return resp;
}

export const getFieldDefinitions = () => {
  return [
    {name: 'acuity',          label: 'Acuity',    column: 0, position: 0, type: 'select', options: [{label:'Stable', value:'Stable'},{label:'Serious', value:'Serious'},{label:'Critical', value:'Critical'}]},
    {name: 'severity',        label: 'Severity',  column: 0, position: 1, type: 'select', options: [{label:'Stable', value:'stable'},{label:'Needs Review', value:'needs_review'},{label:'Serious', value:'serious'},{label:'Not Acceptable', value:'not_acceptable'},{label:'None', value:''}]},
    {name: 'reportAnalysis',  label: 'Analysis',  column: 0, position: 2, type: 'area'},
    {name: 'sample1Finding',  label: 'Finding',   column: 1, position: 0, type: 'text'},
    {name: 'sample1Rate',     label: 'Rate',      column: 1, position: 1, type: 'text'},
    {name: 'pr1',             label: 'Pr',        column: 1, position: 2, type: 'text'},
    {name: 'qrs1',            label: 'Qrs',       column: 1, position: 3, type: 'text'},
    {name: 'qt1',             label: 'Qt',        column: 1, position: 4, type: 'text'},
    {name: 'qtc1',            label: 'Qtc',       column: 1, position: 5, type: 'text'},
    {name: 'sample2Finding',  label: 'Finding',   column: 2, position: 0, type: 'text'},
    {name: 'sample2Rate',     label: 'Rate',      column: 2, position: 1, type: 'text'},
    {name: 'pr2',             label: 'Pr',        column: 2, position: 2, type: 'text'},
    {name: 'qrs2',            label: 'Qrs',       column: 2, position: 3, type: 'text'},
    {name: 'qt2',             label: 'Qt',        column: 2, position: 4, type: 'text'},
    {name: 'qtc2',            label: 'Qtc',       column: 2, position: 5, type: 'text'},
    {name: 'comments',        label: 'Comments',  column: 3, position: 0, type: 'area'}/* ,
    {name: 'preliminaryDiagnosis', column: 0, position: 0, type: 'text'} */
  ];
}

export const getGeneratedReportValues = () => {
  const analysisIndex = Faker.datatype.number(analysis.length);
  const fields = {
    acuity: Faker.helpers.randomize(acuityVals),
    severity: Faker.helpers.randomize(severityVals),
    reportAnalysis: analysis[analysisIndex],
    sample1Finding: Faker.lorem.sentence(),
    sample1Rate: 60 + Faker.datatype.number(50),
    pr1: (120 + Faker.datatype.number(80)) / 1000,
    qrs1: (600 + Faker.datatype.number(400)) / 1000,
    qt1: (260 + Faker.datatype.number(340)) / 1000,
    qtc1: (260 + Faker.datatype.number(340)) / 1000,
    sample2Finding: Faker.lorem.sentence(),
    sample2Rate: 60 + Faker.datatype.number(50),
    pr2: (120 + Faker.datatype.number(80)) / 1000,
    qrs2: (600 + Faker.datatype.number(400)) / 1000,
    qt2: (260 + Faker.datatype.number(340)) / 1000,
    qtc2: (260 + Faker.datatype.number(340)) / 1000,
    comments: Faker.lorem.paragraph(),
    preliminaryDiagnosis: Faker.lorem.paragraph()
  };
  return fields;
}

const makeRequest = async (url, config) => {
  const resp = await fetch(url, config).then(handleErrors);
  return await resp.json();
}

export const getReports = async () => {
  const config  = { ...GET_CONFIG };
  const url = new URL('recent_overreads', endpoint);
  url.searchParams.set('limit', 10);
  return await makeRequest(url, config);
}

export const getReport = async id => {
  const config  = { ...GET_CONFIG };
  const url = new URL(`details/${id}`, endpoint);
  return await makeRequest(url, config);
}

export const sendReport = async data => {
  const config = { ...POST_CONFIG, body: JSON.stringify(data) };
  const url = new URL(SEND_ENDPOINT);
  return await makeRequest(url, config);
}

/* const reports = [
  {
    id:0, partner: 'us-staging', transactionId: 0, recordingDate: new Date(), details: 0, send: 0, delete: 0
  },
  {
    id:1, partner: 'us-staging', transactionId: 1, recordingDate: new Date(), details: 0, send: 0, delete: 0
  }
]; */
export const getReports_mock = async () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(mockReports), 100);
  });
}