
const acuityVals = ["Stable", "Serious", "Critical"];
const severityVals = ["stable", "needs_review", "serious", "not_acceptable", ""];

const mockDetails = {
  duration: 30000,
  inverted: false,
  mains_frequency: '50Hz',
  recorded_at: '2021-09-20 14:47:22',
  recorded_at_offset: 19800,
  comment: null,
  patient_id: null,
  patient_dob: '1970-02-01',
  avg_heart_rate: 80.0,
  low_pass_filter: null,
  baseline_filter: true,
  mains_filter: 'off',
  measurement_position: null,
  hdl: null,
  valve_disease: null,
  significant_palpitations: null,
  myocardial_infarction: null,
  murmur: null,
  long_qt_syndrome: null,
  diabetes_mellitus: null,
  coronary_heart_disease: null,
  atrial_fibrillation: null,
  total_cholesterol: null,
  other_cardiac_description: null,
  other_arrhythmia_description: null,
  cholesterol: null,
  cardiomyopathy: null,
  blood_pressure_sys: null,
  blood_pressure_dia: null,
  activity_level: null,
  height: 182.0,
  weight: null,
  medications: null,
  smoker: false,
  blood_pressure: null,
  other_arrhythmia: null,
  other_cardiac: null,
  user_id: '7kyp5ddeady5783num35n7784',
  country: 'us',
  locale: 'en-US',
  activities: null,
  symptoms: null,
  patient_sex: 'F',
  patient_first_name: 'Test',
  patient_last_name: 'Soni',
  filter: 'enhanced',
  npi_number: 123456789,
  unlock_request: true,
  algorithm_package:'KAIv1',
  algorithm_determination: 'normal',
  return_url: 'https://us-alivecor-staging.alivecor.com/overread/v1/reports',
  partner_name: 'us-staging'
}

export { acuityVals, severityVals, mockDetails };


