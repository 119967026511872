const analysis = [
  "Sinus Rhythm w/Inverted T Waves / PVCs (20/Minute)",
  "Sinus Rhythm w/1st Degree AVB/Bigeminal PVCs/Junctional Beats (31/min)",
  "Sinus Arrhythmia w/PVC /Low Voltage",
  "Sinus Tachycardia w/ PVCs/PACs",
  "Sinus Rhythm w/ Couplet PACs / Trigeminal PACs / PAC's",
  "Sinus Rhythm-Sinus Bradycardia  w/1st Degree AVB  /PAC",
  "Sinus Bradycardia w/4.2 sec. Pause /Junctional Escape Beat - Junctional Rhythm",
  "Sinus Bradycardia w/ 1st Degree AVB/ IVCD/ Couplet PVCs/ PVCs (27/Min)",
  "Sinus Rhythm - Sinus Arrhythmia w/ PVC  / Artifact",
  "Sinus Bradycardia w/Ventricular Run/PACs",
  "Atrial Fibrillation RVR Sustained w/ PVC's/ Lead Loss",
  "Sinus Rhythm w/Delta Wave/Artifact",
  "Sinus Rhythm w/Couplet PACs/PACs/PVCs/Inverted T-Wave/Artifact",
  "Sinus Rhythm w/MF PVCs (13/min)",
  "Sinus Rhythm - Sinus Tachycardia w/ PVC / Artifact/ Lead Loss",
  "Sinus Arrhythmia -Sinus Tachycardia w/Lead Loss",
  "Sinus Tachycardia w/ Multifocal PVCs/PACs/Artifact/Lead Loss",
  "Sinus Rhythm - Sinus Arrhythmia w/1st Degree AVB /IVCD /Bigeminal PAC's",
  "Possible  Supraventricular Tachycardia w/Lead Loss",
  "Sinus Rhythm w/ 1st Degree AVB/ Couplet PACs/ Artifact",
  "Atrial Fibrillation (Sustained) w/Voltage Change/Artifact",
  "Sinus Rhythm w/ Pacs/ PVCs/ PVC Couplets (20/MIN)",
  "Sinus Bradycardia - Sinus Rhythm w/MF Bigeminal  PVCs/MF PVCs",
  "Sinus Rhythm w/ PACs/Inverted Twave",
  "Atrial Flutter- Sinus Bradycardia w/ 5.4 Sec Pause",
  "Atrial Fibrillation Sustained - Sinus Bradycardia w/5.6 Second Pause",
  "Sinus Tachycardia w/ PSVT/ PAC/ Artifact",
  "Sinus Arrhythmia Sinus Rhythm w/Voltage Change/Lead Loss",
  "Sinus Tachycardia w/ PVC's /PAC's",
  "Sinus Rhythm w/PACs - Possible Supraventricular Tachycardia",
  "Sinus Rhythm w/PAC's /Artifact /Lead Loss",
  "Sinus Rhythm w/ Bigeminal PACs/PVC/Lead Loss",
  "Sinus Rhythm - Sinus Bradycardia w/ Bigeminal PVCs/PVCs",
  "Sustained Atrial Fibrillation w/3.10 Sec. Pause",
  "Sinus Bradycardia-Sinus Rhythm w/ PAC's/ Artifact",
  "Sinus Rhythm w/Junctional Beat /PJC /Couplet PVCs /PSVT",
  "Sinus Rhythm w/PAC /Atrial Run/Artifact",
  "Sinus Tachycardia w/ PVCs/ Artifact (14/min)",
  "Sinus Arrhythmia -Sinus Rhythm w/Atrial Run /PVCs /PACs",
  "Sinus Rhythm - Sinus Bradycardia w/ Bigeminal PACs/ Couplet PACs/ PACs/ PVC",
  "Sinus Rhythm-Sinus Bradycardia w/ Bigeminal PVCs/PVCs/Lead Loss",
  "Atrial Fibrillation RVR-Atrial Fibrillation w/Lead Loss/Artifact",
  "Sustained Atrial Fibrillation  w/ PVC's/Artifact",
  "Sinus Rhythm w/1st Degree AVB/PVC/PACs /Junctional Beats",
  "Sinus Arrhythmia - Sinus Rhythm w/PVCs/Bigeminal PVCs/Artifact",
  "Sinus Tachycardia-Sinus Rhythm w/PVC/PACs",
  "Sustained Atrial Fibrillation RVR w/PVCs/Couplet PVCs",
  "Sinus Rhythm w/Atrial Run/Couplet PACs/PAC/Artifact",
  "Sinus Arrhythmia- Sinus Rhythm w/ PJCs",
  "Sinus Rhythm w/ IVCD/ Bigeminal PAC's/ Couplet PAC's",
  "Sinus Rhythm w/1st Degree AVB/Bigeminal PVCs/Junctional Beats (31/min)",
  "Sinus Bradycardia w/4.2 sec. Pause /Junctional Escape Beat - Junctional Rhythm",
  "Sinus Rhythm w/Junctional Beat /PJC /Couplet PVCs /PSVT",
  "Sinus Rhythm w/1st Degree AVB/PVC/PACs /Junctional Beats",
  "Junctional Tachycardia w/ Sinus Beats/Artifact",
  "Sinus Rhythm w/Couplet PVCs/PVCs/Ventricular Escape Beats",
  "Sinus Tachycardia w/PVCs/Fusion Beats/Artifact",
  "Sinus Rhythm w/ 4.5 Sec Pause/PVC/Junctional Escape Beat",
  "Sinus Bradycardia w/Ventricular Escaped Beat",
  "Sinus Tachycardia w/Junctional Beats/Lead Loss",
  "Sinus Rhythm w/PSVT/Junctional Escape Beats",
  "Sinus Rhythm w/PVCs /Ventricular Escape Beats (20/Minute)",
  "Sinus Rhythm -Sinus Arrhythmia w/ 1st Degree AVB / PVCs/Ventricular Escape Beat",
  "Sinus Rhythm - Sinus Tachycardia w/ Junctional Beat",
  "Brady  Arrhythmia w/ 1st Degree AVB / Junctional Beat",
  "Sinus Bradycardia w/PACs /Junctional Escape Beats",
  "2nd Degree HB Type 1 w/PACs / Junctional Beat /Artifact",
  "Atrial Flutter ( Variable ) w/ PVC / 3.5 Sec Pause/ Sinus Beats",
  "Sinus Bradycardia-Sinus Rhythm w/Junctional Escape Beat/Artifact",
  "Atrial Flutter (Variable) w/3.5 sec. Pause /Sinus Beat - Sinus Rhythm w/1st Degree AVB /Bigeminal PAC's",
  "Atrial Fibrillation RVR(Non sustained) w/Sinus  Beat - Sinus Arrhythmia",
  "Sinus Rhythm w/Bigeminal  PACs /PACs/Couplet PACs /Junctional Beats",
  "Sinus Rhythm w/PVCs/Fusion Beats/Voltage Change",
  "Sinus Arrhythmia w/ Junctional Escape Beat/PAC/Artifact/Lead Loss",
  "Sinus Rhythm w/PACs /Junctional Beats",
  "2nd Degree AVB Type 2 w/ Ventricular Escape Beats/ Ventricular Run",
  "Sinus Rhythm-Sinus Arrhythmia w/PACs/PVCs//Junctional Beats/Inverted T Wave/Artifact",
  "Accelerated Junctional Rhythm-Junctional Rhythm w/3.3 Second Pause/PACs/Sinus Beats",
  "Sinus Rhythm w/PVCs/PAC/Junctional Beat /Artifact",
  "Sinus Rhythm w/Trigeminal PJCs/Junctional Escape Beat",
  "Sinus Bradycardia - Bradyarrhythmia w/1st Degree AVB/3.2 Sec Pause/Junctional Escape Beat",
  "Sinus Bradycardia w/1st Degree AVB/PVC/Junctional Beats//Lead Loss",
  "Paced Rhythm w/Sinus Beats/Artifact",
  "Sinus Bradycardia - Sinus Rhythm w/ 1st Degree AVB/3.1 Second Pause/Bigeminal PVCs/PVCs/Ventricular Escape Beat",
  "3rd Degree HB (Complete) w/Ventricular Escape Beat/Artifact",
  "Sinus Bradycardia w/ 1st Degree AVB/PVC/ Ventricular Escape Beat",
  "Sinus Bradycardia w/ Junctional Beats/ PAC",
  "Sinus Tachycardia w/Ventricular Escape Beat/Artifact",
  "Sinus Rhythm w/PACs/ Junctional Escape Beat",
  "Sinus Rhythm w/ 3.0 Second Pause/PVC/Junctional Escape Beats",
  "Accelerated Junctional Rhythm w/Sinus Beats",
  "Sinus Tachycardia w/ IVCD / Junctional Escape Beat / Lead Loss/ Artifact",
  "Sinus Bradycardia w/PACs/Atrial Run/Junctional Escape Beat",
  "Sinus Rhythm w/Couplet PVCs/PVCs/Junctional Beats",
  "2nd Degree HB Type 1 w/PVC/Junctional Escape Beats",
  "Accelerated Junctional Rhythm w/PJC/Atrial Escape Beat",
  "Atrial Flutter (Variable) w/IVCD/Sinus Beats",
  "2nd Degree AVB Type 1 - Sinus Rhythm w/1st Degree AVB /Junctional Escape Beats",
  "Junctional Rhythm w/PVCs/PACs/Sinus Beats/Artifact",
  "Wide Complex Tachycardia - Atrial Fibrillation RVR w/ Sinus Beats",
  "Sinus Rhythm w/Inverted T Waves / PVCs (20/Minute)",
  "Sinus Rhythm w/1st Degree AVB/Bigeminal PVCs/Junctional Beats (31/min)",
  "Sinus Arrhythmia w/PVC /Low Voltage",
  "Sinus Tachycardia w/ PVCs/PACs",
  "Sinus Rhythm w/ Couplet PACs / Trigeminal PACs / PAC's",
  "Sinus Rhythm-Sinus Bradycardia  w/1st Degree AVB  /PAC",
  "Sinus Bradycardia w/ 1st Degree AVB/ IVCD/ Couplet PVCs/ PVCs (27/Min)",
  "Sinus Rhythm - Sinus Arrhythmia w/ PVC  / Artifact",
  "Sinus Bradycardia w/Ventricular Run/PACs",
  "Atrial Fibrillation RVR Sustained w/ PVC's/ Lead Loss",
  "Sinus Rhythm w/Couplet PACs/PACs/PVCs/Inverted T-Wave/Artifact",
  "Sinus Rhythm w/MF PVCs (13/min)",
  "Sinus Rhythm - Sinus Tachycardia w/ PVC / Artifact/ Lead Loss",
  "Sinus Tachycardia w/ Multifocal PVCs/PACs/Artifact/Lead Loss",
  "Sinus Rhythm - Sinus Arrhythmia w/1st Degree AVB /IVCD /Bigeminal PAC's",
  "Sinus Rhythm w/ 1st Degree AVB/ Couplet PACs/ Artifact",
  "Sinus Rhythm w/ Pacs/ PVCs/ PVC Couplets (20/MIN)",
  "Sinus Bradycardia - Sinus Rhythm w/MF Bigeminal  PVCs/MF PVCs",
  "Sinus Rhythm w/ PACs/Inverted Twave",
  "Sinus Tachycardia w/ PSVT/ PAC/ Artifact",
  "Sinus Tachycardia w/ PVC's /PAC's",
  "Sinus Rhythm w/PACs - Possible Supraventricular Tachycardia",
  "Sinus Rhythm w/PAC's /Artifact /Lead Loss",
  "Sinus Rhythm w/ Bigeminal PACs/PVC/Lead Loss",
  "Sinus Rhythm - Sinus Bradycardia w/ Bigeminal PVCs/PVCs",
  "Sinus Bradycardia-Sinus Rhythm w/ PAC's/ Artifact",
  "Sinus Rhythm w/Junctional Beat /PJC /Couplet PVCs /PSVT",
  "Sinus Rhythm w/PAC /Atrial Run/Artifact",
  "Sinus Tachycardia w/ PVCs/ Artifact (14/min)",
  "Sinus Arrhythmia -Sinus Rhythm w/Atrial Run /PVCs /PACs",
  "Sinus Rhythm - Sinus Bradycardia w/ Bigeminal PACs/ Couplet PACs/ PACs/ PVC",
  "Sinus Rhythm-Sinus Bradycardia w/ Bigeminal PVCs/PVCs/Lead Loss",
  "Sustained Atrial Fibrillation  w/ PVC's/Artifact",
  "Sinus Rhythm w/1st Degree AVB/PVC/PACs /Junctional Beats",
  "Sinus Arrhythmia - Sinus Rhythm w/PVCs/Bigeminal PVCs/Artifact",
  "Sinus Tachycardia-Sinus Rhythm w/PVC/PACs",
  "Sustained Atrial Fibrillation RVR w/PVCs/Couplet PVCs",
  "Sinus Rhythm w/Atrial Run/Couplet PACs/PAC/Artifact",
  "Sinus Arrhythmia- Sinus Rhythm w/ PJCs",
  "Sinus Rhythm w/ IVCD/ Bigeminal PAC's/ Couplet PAC's",
  "Junctional Rhythm w/Bigeminal PJCs/PVC",
  "Sinus Rhythm w/ Atrial Runs/PACs/Lead Loss",
  "Atrial Fibrillation RVR  Sustained w/ MF PVCs /Artifact",
  "Sinus Rhythm w/PVCs -Sinus Arrhythmia",
  "Sinus Rhythm w/ IVCD/ PVCs/ PACs",
  "Sinus Rhythm - Sinus Bradycardia w/3.1 Second Pause/PACs",
  "Sinus Rhythm w/Couplet PVCs/PVCs/Ventricular Escape Beats",
  "Sinus Arrhythmia w/ PVC w /Artifact",
  "Sinus Rhythm w/1st Degree AVB/Atrial Run/Couplet  PVCs/Artifact/Lead Loss",
  "Sinus Rhythm w/ PAC/IVCD/Artifact",
  "Sinus Rhythm w/Inverted T Waves / PVCs (20/Minute)",
  "Sinus Arrhythmia w/PVC /Low Voltage",
  "Sinus Bradycardia w/ 1st Degree AVB/ IVCD/ Couplet PVCs/ PVCs (27/Min)",
  "Sinus Rhythm - Sinus Arrhythmia w/ PVC  / Artifact",
  "Sinus Bradycardia w/Ventricular Run/PACs",
  "Atrial Fibrillation RVR Sustained w/ PVC's/ Lead Loss",
  "Sinus Rhythm w/Delta Wave/Artifact",
  "Sinus Rhythm w/Couplet PACs/PACs/PVCs/Inverted T-Wave/Artifact",
  "Sinus Rhythm - Sinus Tachycardia w/ PVC / Artifact/ Lead Loss",
  "Sinus Arrhythmia -Sinus Tachycardia w/Lead Loss",
  "Sinus Tachycardia w/ Multifocal PVCs/PACs/Artifact/Lead Loss",
  "Sinus Rhythm - Sinus Arrhythmia w/1st Degree AVB /IVCD /Bigeminal PAC's",
  "Possible  Supraventricular Tachycardia w/Lead Loss",
  "Sinus Rhythm w/ 1st Degree AVB/ Couplet PACs/ Artifact",
  "Atrial Fibrillation (Sustained) w/Voltage Change/Artifact",
  "Sinus Rhythm w/ PACs/Inverted Twave",
  "Sinus Tachycardia w/ PSVT/ PAC/ Artifact",
  "Sinus Arrhythmia Sinus Rhythm w/Voltage Change/Lead Loss",
  "Sinus Rhythm w/PAC's /Artifact /Lead Loss",
  "Sinus Rhythm w/ Bigeminal PACs/PVC/Lead Loss",
  "Sinus Bradycardia-Sinus Rhythm w/ PAC's/ Artifact",
  "Sinus Rhythm w/PAC /Atrial Run/Artifact",
  "Sinus Tachycardia w/ PVCs/ Artifact (14/min)",
  "Sinus Arrhythmia -Sinus Rhythm w/Atrial Run /PVCs /PACs",
  "Sinus Rhythm-Sinus Bradycardia w/ Bigeminal PVCs/PVCs/Lead Loss",
  "Atrial Fibrillation RVR-Atrial Fibrillation w/Lead Loss/Artifact",
  "Sustained Atrial Fibrillation  w/ PVC's/Artifact",
  "Sinus Arrhythmia - Sinus Rhythm w/PVCs/Bigeminal PVCs/Artifact",
  "Sinus Rhythm w/Atrial Run/Couplet PACs/PAC/Artifact",
  "Sinus Rhythm w/ IVCD/ Bigeminal PAC's/ Couplet PAC's",
  "Sinus Tachycardia - Supraventricular Tachycardia w/ Voltage Change",
  "Sinus Rhythm w/ Atrial Runs/PACs/Lead Loss",
  "Atrial Fibrillation RVR  Sustained w/ MF PVCs /Artifact",
  "Sinus Rhythm w/ IVCD/ PVCs/ PACs",
  "Junctional Tachycardia w/ Sinus Beats/Artifact",
  "Sinus Tachycardia -Sinus Rhythm w/Axis Change",
  "Sinus Arrhythmia w/ PVC w /Artifact",
  "Sinus Rhythm w/1st Degree AVB/Atrial Run/Couplet  PVCs/Artifact/Lead Loss",
  "Atrial Fibrillation - Atrial Fibrillation RVR w/Artifact",
  "Sinus Rhythm w/ PAC/IVCD/Artifact",
  "Sinus Bradycardia w/IVCD/Trigeminal PACs/PACs",
  "Sinus Rhythm w/ 1st Degree AVB /PACs /Axis Change",
  "Supraventricular Tachycardia  Sinus Rhythm w/Artifact",
  "Accelerated Junctional Rhythm - Junctional Rhythm w/ST Elevation/Artifact",
  "Sinus Arrhythmia-Sinus Rhythm w/ PACs/Artifact/Lead Loss",
  "Sinus Bradycardia - Sinus Rhythm w/ Lead Loss/ Voltage Change",
  "Sinus Tachycardia w/PVCs/Lead Loss (22/min)",
  "Sinus Rhythm w/ Bigeminal PVCs/PVCs/Artifact (41/min)",
  "Sinus Tachycardia w/PVCs/Fusion Beats/Artifact",
  "Sinus Rhythm w/1st Degree AVB/PVCs/Ventricular Run",
  "Sinus Bradycardia w/4.2 sec. Pause /Junctional Escape Beat - Junctional Rhythm",
  "Atrial Flutter- Sinus Bradycardia w/ 5.4 Sec Pause",
  "Atrial Fibrillation Sustained - Sinus Bradycardia w/5.6 Second Pause",
  "Sustained Atrial Fibrillation w/3.10 Sec. Pause",
  "Sinus Rhythm - Sinus Bradycardia w/3.1 Second Pause/PACs",
  "Sinus Bradycardia-Sinus Arrhythmia  w/1st Degree AVB/ 3.2 Second Pause /PVC/PAC",
  "Atrial Fibrillation RVR- Sinus Bradycardia w/ 4.7 Sec Pause/ PACs",
  "Atrial Flutter (Variable) - Sinus Rhythm w/ 7.0 s Pause",
  "Sinus Rhythm w/4.0 Sec Pause",
  "Sinus Rhythm- Sinus Bradycardia w/8.6 Sec Pause",
  "Sinus Rhythm w/ 4.5 Sec Pause/PVC/Junctional Escape Beat",
  "Sinus Rhythm w/ 3.1 Sec Pause/ PVCs",
  "Sinus Bradycardia w/ 3.0 Sec Pause/ 1st Degree AVB/ PVCs",
  "Sinus Bradycardia w/ 1st Degree AVB/3.0 Second Pause",
  "Sinus Bradycardia- Sinus Tachycardia w/3.3 Sec Pause",
  "Sustained Atrial Fibrillation RVR w/ 3 Sec Pause",
  "Sinus Bradycardia-Sinus Rhythm w/ 1st Degree AVB/3.0 Second Pause/Bigeminal PVCs",
  "Sinus Rhythm-Sinus Arrhythmia w/ 1st Degree AVB/3.6 Second Pause",
  "Atrial Fibrillation Sustained w/3 Sec. Pause/Voltage Change",
  "Sinus Bradycardia -Sinus Rhythm w/ 1st Degree AVB / 3.3 Sec Pause/ MF PVCs",
  "Non Sustained Atrial Fibrillation RVR -Sinus Bradycardia w/ 3.3 Sec Pause",
  "Sinus Rhythm w/4.8 Sec Pause",
  "Atrial Flutter (Variable)- Sinus Bradycardia w/ 1st Degree AVB/ 3.7 Sec Pause/ Couplet PACs /PAC",
  "Sinus Rhythm w/3.0 Second Pause /PAC",
  "Sinus Rhythm w/ 3.3 Sec Pause",
  "12.0 Sec Pause",
  "Atrial Fibrillation Sustained w/3.0 Sec. Pause /PVC",
  "Sinus Bradycardia w/ 3.8 s Pause",
  "Sinus Bradycardia - Junctional Rhythm w/ 3.7 Sec Pause",
  "Sinus Bradycardia -Atrial Flutter (Variable) w/4.7 Second Pause /PACs",
  "Atrial Flutter (Variable) w/IVCD /3.4 sec. Pause - Sinus Rhythm w/1st Degree AVB /IVCD /PJC's",
  "2nd Degree AVB Type 1 - Sinus Bradycardia w/ 1st Degree AVB/3.5 Second Pause",
  "Atrial Flutter ( Variable ) w/ PVC / 3.5 Sec Pause/ Sinus Beats",
  "Sustained  Atrial Fibrillation w/3.1 Sec. Pause",
  "Atrial Flutter (Variable) w/IVCD/ 4.2 Sec Pause",
  "Atrial Flutter (Variable) w/ 3.9 Sec Pause",
  "Sinus Bradycardia w/IVCD/3.4 Sec. Pause /PAC",
  "Atrial Fibrillation w/3.2 Sec Pause/Inverted T Wave",
  "Atrial Flutter (Variable) w/3.5 sec. Pause /Sinus Beat - Sinus Rhythm w/1st Degree AVB /Bigeminal PAC's",
  "Atrial Flutter (Variable) w/ 3.4 Sec Pause/ PVC",
  "Atrial Fibrillation Non Sustained-Sinus Rhythm w/3.8 Second Pause/IVCD/Lead Loss/Artifact",
  "Sinus Rhythm w/1st Degree AVB/PACs/4.4 Sec. Pause",
  "Atrial Fibrillation Sustained w/3.5 Second Pause/PVCs",
  "Atrial Fibrillation Sustained w/3.4 Second Pause/PVC",
  "Atrial Fibrillation w/3.0 Sec. Pause/Lead Loss",
  "Atrial Fibrillation  Sustained w/ 3.4 Sec. Pause",
  "Atrial Flutter (Variable)  - Sinus Arrhythmia  w/3.4 Sec Pause",
  "2nd Degree AVB Type 1 w/3.0 sec. Pause /Artifact",
  "Sinus Arrhythmia w/3.0 Sec Pause",
  "Atrial Fibrillation Sustained w/3.7Second Pause /PVC"
];

export default analysis;
