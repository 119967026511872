import { TextareaAutosize, InputLabel, Grid } from '@material-ui/core';

const Textarea = props => {
  
  return (
    <Grid item xs={12}>
      <InputLabel id="answer-label">{props.label || props.name}:</InputLabel>
      <TextareaAutosize name={props.name} value={props.value} label={props.label||props.name} onChange={props.onChange} minRows="4" style={{width:'100%'}} />
    </Grid>
  )
}

export default Textarea;