import { useEffect, useState, Fragment } from 'react';
import {  Grid,
          Dialog,
          DialogContent,
          DialogContentText,
          DialogTitle
        } from '@material-ui/core';


const Details = (props) => {
  const [report, updateReport] = useState(props.report);
  useEffect(() => updateReport(props.report), [props.report]);

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth onClose={props.close}>
      <DialogTitle>Report Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <DialogContentText>
              Column 1
            </DialogContentText>
          </Grid>
          <Grid item xs={8}>
            <DialogContentText>
              Column 2
            </DialogContentText>
          </Grid>
        {Object.keys(report).map((key, i) => {
          return (
            <Fragment>
              <Grid item xs={4}>
               {key}
              </Grid>
              <Grid item xs={8}>
                {report[key] instanceof Date ? +report[key]: report[key]}
              </Grid>
            </Fragment>
          )
        })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default Details;