import { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import {  Grid,
          Dialog,
          DialogActions,
          DialogContent,
          DialogContentText,
          DialogTitle,
          Button
        } from '@material-ui/core';
import styles from './index.module.css';
import { getFieldDefinitions, getGeneratedReportValues, sendReport } from '../../data';
import TextInput from './text-field';
import SelectInput from './select-input';
import Textarea from './textarea';

const Editor = props => {
  const [ errors/* , updateErrors */ ] = useState([]);
  const [ report, updateReport ] = useState(props.report);
  const [ fields, updateFields ] = useState([]);

  const getFields = (column) => {
    const columnFields = fields.map((field, i) => {
      let el;
      if(field.column === column) {
        switch(field.type) {
          case 'text':
            el = <TextInput name={field.name} value={report[field.name] || ''} label={field.label||field.name} onChange={eventHandlers.fieldUpdate} index={i} />
            break;
          case 'select':
            el = <SelectInput name={field.name} value={report[field.name] || ''} label={field.label||field.name} onChange={eventHandlers.fieldUpdate} options={field.options} index={i} />
            break;
          case 'area':
            el = <Textarea name={field.name} value={report[field.name] || ''} label={field.label||field.name} onChange={eventHandlers.fieldUpdate} index={i} />
            break;
          default:
            break;
        }
      }
      return el;
    });
    return columnFields;
  }
  const eventHandlers = {
    submit: ev => {
      sendReport(report);
    },
    fieldUpdate: ev => {
      updateReport({
        ...report,
        [ev.target.name]: ev.target.value
      });
    },
    generateValues: async () => {
      const values = await getGeneratedReportValues();
      const combinedValues = {
        ...report,
        ...values
      };
      updateReport(combinedValues);
    },
    cancel: () => {
      props.close();
    }
  }
  useEffect(() => { updateReport(props.report) }, [props.report]);
  useEffect(() => {
    (async () => {
      const formFill = await getFieldDefinitions();
      updateFields(formFill);
    })()
  }, []);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth onClose={props.close}>
      <DialogTitle>Edit Report</DialogTitle>
      {errors.map((error, i) => <Alert severity="warning" key={i}>{error}</Alert>)}
      <form onSubmit={eventHandlers.submit}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <DialogContentText>Sample 1</DialogContentText>
            </Grid>
            <Grid item xs={4}>
              <DialogContentText>Sample 2</DialogContentText>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                {getFields(0)}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
              {getFields(1)}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
              {getFields(2)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
              {getFields(3)}
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles['button-container']}>
              <DialogActions>
                <Button variant="contained" onClick={eventHandlers.cancel}>Cancel</Button>
                <Button variant="contained" onClick={eventHandlers.generateValues}>Generate Values</Button>
                <Button variant="contained" onClick={eventHandlers.submit} color="primary">Send</Button>
              </DialogActions>
            </Grid>
          </Grid>

        </DialogContent>
      </form>
    </Dialog>
  )
}

export default Editor;