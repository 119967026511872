import { app as appInitialState } from '../store/initial-state';

export const ENDPOINT = `${appInitialState.ENDPOINT}api/overread/v1/`;
export const SEND_ENDPOINT = appInitialState.SEND_ENDPOINT;

const headers = { 'Content-Type': 'application/json', Authorization: '362dg8verbrlhtbz1u66e3n0p' }
export const GET_CONFIG = {
  method: 'GET',
  mode: 'cors',
  headers
};
export const PUT_CONFIG = {
  method: 'PUT',
  mode: 'cors',
  headers
}
export const POST_CONFIG = {
  method: 'PUT',
  mode: 'cors',
  headers
}

export const handleErrors = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText || response.message);
  }
  return response;
}