import { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Link, Button, Container } from '@material-ui/core';
import { getReports } from './data';
import { mockDetails } from './data/constants';
import Editor from './components/editor';
import Details from './components/details';

function App() {
  const [ reports, updateReports ] = useState([]);
  const [ reportsLoading, updateReportsLoading ] = useState(false);
  const [ detailsVisible, updateDetailsVisible ] = useState(false);
  const [ editorVisible, updateEditorVisible ] = useState(false);
  const [ focusedReport, updateFocusedReport ] = useState({});
  const [ focusedDetails, updateFocusedDetails ] = useState({});

  const eventHandlers = {
    onClick: (action, ev) => {
      ev.preventDefault();
      action();
    },
    onId: report => {
      // open edit report dialog
      console.log('id click', report);
    },
    onDetails: report => {
      updateFocusedDetails({
        ...report,
        ...mockDetails
      });
      updateDetailsVisible(true);
      setTimeout(() => {console.log('focusedReport', focusedReport)}, 1000);
    },
    onEdit: report => {
      updateEditorVisible(true);
      updateFocusedReport(report);
    },
    onCloseEditor: () => {
      updateEditorVisible(false);
      updateFocusedReport({});
    },
    onCloseDetails: () => {
      updateDetailsVisible(false);
      updateFocusedDetails({});
    }
  }

  const dataGridColumnsConfig = [
    {
      field: 'id',
      headerName: 'Id',
      width: 75,
      renderCell: (params) => {
        const onClick = eventHandlers.onClick.bind(null, eventHandlers.onId.bind(null, params.row));
        return <Link href="#" onClick={onClick}>{params.row.id}</Link>
      }
    },
    {
      field: 'partner', headerName: 'Partner', width:'200'
    },
    {
      field: 'transactionId', headerName: 'Transaction Id', width:'200'
    },
    {
      field: 'recordingDate', headerName: 'Recorded', width:'200'
    },
    {
      field: 'details',
      headerName: ' ',
      disableColumnMenu: true,
      sortable:false,
      renderCell: (params) => {
        const onClick = eventHandlers.onClick.bind(null, eventHandlers.onDetails.bind(null, params.row));
        return <Button variant="contained" onClick={onClick}>Details</Button>
      }
    },
    {
      field: 'edit',
      headerName: ' ',
      disableColumnMenu: true,
      sortable:false,
      renderCell: (params) => {
        const onClick = eventHandlers.onClick.bind(null, eventHandlers.onEdit.bind(null, params.row));
        return <Button variant="contained" onClick={onClick}>Edit</Button>
      }
    },
  ];

  useEffect(() => {
    (async () => {
      updateReportsLoading(true);
      const data = await getReports();
      updateReports(data);
      updateReportsLoading(false);
    })();
  }, []);
  return (
    <Container style={{ height: '100vh' }}>
      <DataGrid rows={reports} columns={dataGridColumnsConfig} autoPageSize={true} disableSelectionOnClick loading={reportsLoading} />
      <Editor open={editorVisible} report={focusedReport} close={eventHandlers.onCloseEditor} />
      <Details open={detailsVisible} report={focusedDetails} close={eventHandlers.onCloseDetails} />
    </Container>
  );
}

export default App;
