import { Select, MenuItem, FormControl, InputLabel, Grid } from '@material-ui/core';

const SelectInput = (props) => {
  return (
    <Grid item xs={12}>
      <FormControl fullWidth={true} key={props.index}>
        <InputLabel id={`${props.name}-label`}>{props.label||props.name}:</InputLabel>
        <Select label-id={`${props.name}-label`} name={props.name} label={props.label||props.name} value={props.value} onChange={props.onChange}>
          {props.options.map((option, i) => <MenuItem value={option.value} key={i}>{option.label}</MenuItem>)}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default SelectInput;